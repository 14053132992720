import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import scrollTo from 'gatsby-plugin-smoothscroll'

const Footer = ({ footnote }) => (
  <footer className="nav-footer container container--wide">
    <ul className="three-footer">
      <li className="first">
        <Link to="/info/faq/">FAQs</Link>
      </li>
      <li className="middle">
        <Link to="/info/access/">Access <span className="extra">Policy</span></Link>
      </li>
      <li className="last">
        <Link to="/info/terms/">{footnote && <sup>&#8225; </sup>}Terms <span className="extra">&amp; Conditions</span></Link>
      </li>
    </ul>
    <button className="back-to-top" onClick={() => scrollTo('#top')}>
      <i className="icon-ios-arrow-up"></i>
      BACK TO TOP
    </button>
  </footer>
)

Footer.propTypes = {
  footnote: PropTypes.bool,
}

export default Footer
