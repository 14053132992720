import React from 'react';
import Play from '../images/play.inline.svg'
import Pause from '../images/pause.inline.svg'
import Img from "gatsby-image/withIEPolyfill";

const AudioControls = (
    {
        isPlaying,
        onPlayPauseClick,
        onPrevClick,
        onNextClick,
        isReady,
    }) => (
    <div className="audio-controls">
        {isPlaying ? (
            <button
                type="button"
                className="pause"
                onClick={() => onPlayPauseClick(false)}
                aria-label="Pause"
            >
                <Pause/>
            </button>
        ) : (
            <button
                type="button"
                className="play"
                onClick={() => onPlayPauseClick(true)}
                aria-label="Play"
                disabled={!isReady}
            >
                <Play/>
            </button>
        )}
    </div>
)

const Backdrop = (
    {
        activeColor,
        trackIndex,
        isPlaying,
        onClick,
    }) => {
    React.useEffect(() => {
        document.documentElement.style.setProperty('--active-color', activeColor);
    }, [trackIndex]);

    return (
        <div onClick={onClick} className={`color-backdrop ${isPlaying ? 'playing' : 'idle'}`}/>
    )
}

const AudioPlayer = ({tracks, backdropClick, trackIndexToPlay = 0}) => {

    const [trackIndex, setTrackIndex] = React.useState(trackIndexToPlay)
    const [trackProgress, setTrackProgress] = React.useState(0)
    const [isPlaying, setIsPlaying] = React.useState(false)

    const {title, color = '', image, src} = tracks[trackIndex];

    const audioRef = React.useRef(new Audio(src))
    const intervalRef = React.useRef();
    const isReady = React.useRef(false)

    const {duration} = audioRef.current

    React.useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
            startTimer();
        } else {
            clearInterval(intervalRef.current);
            audioRef.current.pause();
        }
    }, [isPlaying])

    React.useEffect(() => {
        audioRef.current.pause();

        audioRef.current = new Audio(src);
        setTrackProgress(audioRef.current.currentTime);

        if (isReady.current) {
            audioRef.current.play();
            setIsPlaying(true);
            startTimer();
        }
    }, [trackIndex])

    React.useEffect(() => {
        // Pause and clean up on unmount
        audioRef.current.addEventListener('canplaythrough', (event) => {
            isReady.current = true
            setIsPlaying(true)
        })

        audioRef.current.load(); // for iOS Browsers -- see: https://stackoverflow.com/a/49794011

        return () => {
            audioRef.current.pause()
            clearInterval(intervalRef.current)
        }
    }, []);

    const startTimer = () => {
        // Clear any timers already running
        clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            setTrackProgress(audioRef.current.currentTime);
        }, 1000);
    }

    const onScrub = (value) => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
        audioRef.current.currentTime = value;
        setTrackProgress(audioRef.current.currentTime);
    }

    const onScrubEnd = () => {
        // If not already playing, start
        if (!isPlaying) {
            setIsPlaying(true);
        }
        startTimer();
    }

    const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : '0%'
    const trackStyling = `
      -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
    `

    const formatTime = seconds => {
        const s = seconds % 60
        const m = (seconds - s) % 3600
        const h = seconds - m - s

        const str = `${Math.floor(m) < 600 ? '0' : ''}${Math.floor(m / 60)}:${Math.floor(s) < 10 ? '0' : ''}${Math.floor(s)}`

        if (h) {
            return `${Math.floor(h / 3600)}:` + str
        } else {
            return str
        }
    }

    const timeDisplay = duration ? `${formatTime(trackProgress)} / ${formatTime(duration)}` : 'Loading...'

    return (
        <div className="audio-player">
            <div className="track-info">
                <Img className="artwork"
                     alt={title}
                     fluid={image.localFile.childImageSharp.fluid}/>
                <h2 className="title">{title}</h2>
                <h3 className="time-display">{timeDisplay}</h3>
                <AudioControls
                    isReady={isReady.current}
                    isPlaying={isPlaying}
                    onPlayPauseClick={setIsPlaying}
                />
                <input
                    type="range"
                    value={trackProgress}
                    step="1"
                    min="0"
                    max={duration ? duration : `${duration}`}
                    className="progress"
                    onChange={(e) => onScrub(e.target.value)}
                    onMouseUp={onScrubEnd}
                    onKeyUp={onScrubEnd}
                    style={{ background: trackStyling }}
                />
            </div>
            <Backdrop
                trackIndex={trackIndex}
                activeColor={color}
                isPlaying={isPlaying}
                onClick={backdropClick}
            />
        </div>
    )

}

export default AudioPlayer;
