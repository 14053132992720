import React from "react"
import {graphql} from 'gatsby'

import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import Img from "gatsby-image/withIEPolyfill";
import AudioPlayer from '../../../components/AudioPlayer'

export default ({location, data}) => {
    const page = data.strapiCenturionCalmTransformation

    const audioList = page.contents?.reduce((prev, current) => prev.concat(current.media), [])
    const audioData = audioList.map(({image, title, length, description, src}) => ({
        title, description, image, src
    }))

    const [playerIsOpen, setPlayerIsOpen] = React.useState(false)
    const [trackIndexToPlay, setTrackIndexToPlay] = React.useState(0)

    const playAudio = src => () => {
        setPlayerIsOpen(open => !open)
        setTrackIndexToPlay(audioData.findIndex(data => data.src === src))
    }

    const content = page.contents.map(content =>
        <div>
            <h3 className="font-semibold text-center mb-8 text-3xl text-black">{content.heading}</h3>
            <div className="tw-grid calm-auto-grid-cols-3 gap-6">
                {content.media.map(({image, title, length, description, src, type}) => (
                    <div className="m-auto card max-w-xs w-full h-96 bg-white rounded-2xl overflow-hidden hover:shadow-xl" onClick={playAudio(src)}>
                        <Img className="w-full h-48 lg:h-52" objectFit="cover"
                             fluid={image.localFile.childImageSharp.fluid}/>
                        <div className="p-3">
                            <div className="font-semibold uppercase text-calmType mt-0 lg:mt-0.5 mb-2">{type} • {length}</div>
                            <h4 className="font-semibold text-calmTitle text-black mt-0 mb-0.5">{title}</h4>
                            <div className="text-calmDesc pb-4 mb-1.5">{description}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )

    return (
        <Layout>
            <SEO metaTitle={page.metaTitle}/>
            <Header location={location}/>
            <div className="container container--med island--dubs text-center calm-font">
                <h3 className="w-5/6 m-auto font-semibold text-calmHeading text-black" dangerouslySetInnerHTML={{__html: page.title}} />
                <p dangerouslySetInnerHTML={{__html: page.intro}}/>
            </div>
            <div className="wcontainer w-full m-auto calm-font">
                <div className="py-14 sm:px-52 w-full bg-gray-100 flex gap-y-14 flex-col">
                    {content}
                </div>

                {playerIsOpen && <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
                     aria-modal="true" >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 p-20 text-center">

                        <div className="fixed inset-0 bg-gray-100 bg-opacity-90 m-auto transition-opacity" aria-hidden="true"/>

                        <span className="hidden" aria-hidden="true">&#8203;</span>
                        <AudioPlayer tracks={audioData} trackIndexToPlay={trackIndexToPlay} backdropClick={() => {setPlayerIsOpen(false)}} />

                    </div>
                </div>}

                <div className="banner py-20 w-full bg-gray-300 text-center">
                    <h3 className="underline font-semibold text-calmFooterHeading">Get 40% off a 1-year subscription to Calm.</h3>
                    <p className="text-xl py-6">Discover how mindfulness can aid your travel experience.</p>

                    <button className="bg-white hover:bg-gray-200 border-0 text-black text-calmTitle font-semibold py-4 px-16 rounded-lg"
                        onClick={() => {
                            window.open("https://www.calm.com/new-member-offer?coupon=wellnesshub", '_blank')
                        }}>
                        Get 40% off
                    </button>
                </div>
            </div>

            <Footer footnote={true}/>
        </Layout>
    )
}

export const query = graphql`
  query {
    strapiCenturionCalmTransformation {
      metaTitle
      title
      intro
      infoBar
      contents {
        heading
        media {
          image {
            localFile	{
                childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
            }
            }
          }
          title
          length
          description
          src
          type
        }
      }
    }
  }
`
